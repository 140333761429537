import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import MenuHeader from '../components/MenuHeader';
import { DietFilterModalWrapper, UpsellModalWrapper } from '../../../helpers/modalHelpers';
import { historyMW } from '../../../helpers/routingHelpers';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import PopupBannerController from '../../../components/PopupBannerController';
import Notification from '../components/Notification';
import { useBreakPoint } from '../../../hooks/useBreakPoint';
import Layout from '../../../components/Layout';
import ItemMenu from '../components/ItemMenu';
import { setCollectionTime, setHasSeenBasket } from '../../../store/sessionSlice';
import { config } from '../../../config';
import { valueWithAppliedRewards } from '../../checkout/components/DeliveryDetails';

const Menu = ({
  menu,
  bundles,
  filters,
  category,
  isLoading,
  selectedCategory,
  setSelectedCategory,
  navigateTo,
  addItem,
  removeItem,
  notification,
  setNotification,
  setQuantity,
  refreshBasket,
  inApp
}) => {
  const [dietModalIsOpen, setDietModalIsOpen] = useState(false);
  const { width, height } = useWindowDimensions();
  const isSmallScreen = useBreakPoint(width, 768);
  const [upsellModalOpen, setUpsellModalOpen] = useState(false);
  const { restaurantId, freshRelevanceInitialised, restaurant, customer, navMenuHeight } = useSelector(state => state.session);
  const basket = useSelector(state => state.basket);
  const outOfStockItems = useSelector(state => state.menu.outOfStockItems);
  const dispatch = useDispatch();
  const pageRef = useRef(null);
  const history = useHistory();
  const containerRef = useRef(null);
  const { loyalty } = useSelector(state => state.session.features);
  const loginBanner = customer === null && loyalty;
  
  const findItemQuantity = (id, isBundle = false) => {
    const items = isBundle ? basket.bundles.filter(el => el.name === id) : basket.items.filter(el => el.id === id);
    return items.reduce((acc, cur) => acc += cur.quantity, 0);
  };

  useEffect(() => {
    dispatch(setHasSeenBasket(false));
    setQuantity(1);
    dispatch(setCollectionTime(''));
    window.ReactNativeWebView?.postMessage(JSON.stringify({ type: 'NAV_BAR', payload: 'true' }));
  }, []);

    useEffect(() => {
        const menuElement = document.querySelector('.menu-wrapper');

        if (menuElement) {
            const menuBounding = menuElement.getBoundingClientRect();
            const bannerElement = document.querySelector('.hero-body');

            if (bannerElement) {
                const bannerBounding = bannerElement.getBoundingClientRect();
                const belowCat = menuBounding.top < navMenuHeight + (inApp ? 87 : bannerBounding.height);

                if (belowCat && navMenuHeight) {
                    const rewardsElement = document?.querySelector('.rewards-container');
                    const rewardsBounding = rewardsElement?.getBoundingClientRect();
                    const scrollToThis = bannerBounding.height + (!loginBanner && rewardsBounding?.height ? rewardsBounding?.height : 0);

                    if (inApp) {
                        document.body.scrollTo(0, scrollToThis);
                    } else {
                        window.scrollTo(0, scrollToThis);
                    }
                }
            }
        }
    }, [selectedCategory, navMenuHeight]);

  const handleBack = () => {
    historyMW.push('', basket.isDelivery, history);
  };

  return (
    <div ref={pageRef}>
      <Layout
        backgroundColour='#3AAA20'
        ctaCopy={basket.isDelivery ? '' : 'Change restaurant'}
        subtitle={
          basket.isDelivery && basket.delivery ? `Estimated delivery time: ${basket?.delivery?.deliveryQuote.duration?.begin} - ${basket?.delivery?.deliveryQuote.duration?.end} mins` : `You are ordering for collection${restaurant ? ' from ' + restaurant.name + ' restaurant.' : '.'}`
        }
        backButton={handleBack}
        extraVerticalPadding
        allergens={true}
        isFluid={true}
        heroPaddingSize='is-xxsmall'
        isSmallerTitle
        customStyles={'is-menu'}
      >
        <MenuHeader
          menu={menu}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          screenWidth={width}
          setDietModalIsOpen={setDietModalIsOpen}
          filter={filters}
          inApp={inApp}
        />

        <div ref={containerRef} className='container' style={{ paddingBottom: '1rem' }} >
          <section className="menu menu-wrapper section is-small is-menu menu-items-wrapper">
            <div className='columns is-flex-wrap-wrap is-align-content-stretch'>
              <ItemMenu
                isLoading={isLoading}
                category={category}
                filters={filters}
                bundles={bundles}
                basket={basket}
                width={width}
                isSmallScreen={isSmallScreen}
                navigateTo={navigateTo}
                addItem={addItem}
                removeItem={removeItem}
                findItemQuantity={findItemQuantity}
                selectedCategory={selectedCategory}
                outOfStockItems={outOfStockItems}
              />
            </div>

            <div className={`nutrition-note ${inApp && basket?.numberOfItems ? 'in-app-with-basket' : ''}`}>
              <span>Nutritional information displayed on the menu is given per portion in calories (kcal). All dishes serve one, unless stated otherwise. Adults need around 2000 kcal per day.</span>
            </div>
          </section>
        </div>
        {
          notification &&
          <Notification
            notification={notification}
            setNotification={setNotification}
            screenWidth={width}
          />
        }
        {
          basket.numberOfItems ?
            <>
              <PopupBannerController basket={basket} screenWidth={width} hide parentRef={pageRef} isMenu justifyEnd skipAnimation={isLoading}>
                <button
                  onClick={() => {
                    if (!freshRelevanceInitialised) { setUpsellModalOpen(true); }
                  }}
                  className={'tobasket-btn btn btn-primary w100-mobile'}
                  data-testid={'tobasket'}
                >
                  {`Checkout (${basket.numberOfItems}) - ${config.CURRENCY_SYMBOL}${valueWithAppliedRewards(basket?.subTotal - basket?.voucherReduction, basket?.discounts).toFixed(2)}`}
                </button>
              </PopupBannerController>
            </>
            : null
        }
        <DietFilterModalWrapper inApp={inApp} dietModalIsOpen={dietModalIsOpen} setDietModalIsOpen={setDietModalIsOpen} screenWidth={width} />
        <UpsellModalWrapper
          upsellModalOpen={upsellModalOpen}
          setUpsellModalOpen={setUpsellModalOpen}
          screenWidth={width}
          addItem={addItem}
          removeItem={removeItem}
          navigateTo={navigateTo}
          restaurantID={restaurantId}
          outOfStockItems={outOfStockItems}
          refreshBasket={refreshBasket}
          inApp={inApp}
        />
      </Layout>
    </div>
  );
};

export default React.memo(Menu);
