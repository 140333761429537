import * as Sentry from '@sentry/react';
import React, { useEffect, useRef } from 'react';
import braintree from 'braintree-web';
import { freshRelevance, gtmEvent } from '../../../helpers/commonHelpers';
import { config } from '../../../config';
import { paymentTypes } from '../../../contants/paymentType';
import { postMessageWithAwait } from '../../../helpers/appHelpers';
import { useSelector } from 'react-redux';

const brand = config.BRAND;
const PayByGooglePay = ({
  clientToken,
  environment,
  grandTotal,
  generatePaymentBody,
  paymentSuccessful,
  submitPaymentFail,
  currentlySubmittingPayment,
  setCurrentlySubmittingPayment,
  pay,
  merchantId,
  inApp,
  setDisablePaymentBackButton
}) => {
  const generatePaymentBodyRef = useRef(null);
  generatePaymentBodyRef.current = generatePaymentBody;
  const { email, mobileNumber } = useSelector(state => state.basket);
  const payments = async (payData) => {
    const response = await pay(generatePaymentBodyRef.current(payData, paymentTypes.Google));
    const data = response.data || response.error?.data;
    if (data?.status === 'OK') {
      freshRelevance('pageChange', null, { 'sendBeacon': true });
      paymentSuccessful(data);
    } else {
      Sentry.captureException(data);
      submitPaymentFail(data);
      setCurrentlySubmittingPayment(false);
    }
  };

  useEffect(() => {
    const renderDropIn = async () => {
      let google = window.google;
      if (!google) return;
      var paymentsClient = new google.payments.api.PaymentsClient({ environment });

      const clientInstance = await braintree.client.create({ authorization: clientToken });

      const googlePaymentInstance = await braintree.googlePayment.create({
        client: clientInstance,
        googlePayVersion: 2,
        googleMerchantId: merchantId // Optional in sandbox; if set in sandbox, this value must be a valid production Google Merchant ID
      });

      const button = paymentsClient.createButton({ buttonType: 'pay', onClick: () => {     
        gtmEvent('googlepay_click', { channel: inApp ? 'App' : 'Web' });} });
      document.getElementById('button-container').appendChild(button);


      button.addEventListener('click', async event => {
        event.preventDefault();

        if (!currentlySubmittingPayment) {
          setDisablePaymentBackButton(true);
          setCurrentlySubmittingPayment(true);
          if (inApp) {
            const payData = {
              googlePayMerchantId: merchantId,
              threeDSecure: {
                email,
                mobilePhoneNumber: mobileNumber
              },
              clientToken,
              countryCode: 'GB',
              currencyCode: 'GBP',
              orderTotal: grandTotal,
              applePay: false,
              googlePay: true,
              vaultManager: false,
              payPal: false,
              cardDisabled: true,
              darkTheme: true,
            }
            try {
              const result = await postMessageWithAwait(
                { type: 'GOOGLE_PAY_START', payload: payData },
                ['GOOGLE_PAY_TOKEN', 'PAYMENT_FAILURE']
              );
              if (!result || result.type === 'PAYMENT_FAILURE') throw new Error('Payment Failure');
              payments(result.payload);
            } catch (err) {
              if (!err.message?.includes('User closed the Payment Request UI.')) {
                Sentry.captureException(err);
                submitPaymentFail();
              }
              setCurrentlySubmittingPayment(false);
              setDisablePaymentBackButton(false);
            }
          } else {
            setDisablePaymentBackButton(true);
            var paymentDataRequest = googlePaymentInstance.createPaymentDataRequest({
              transactionInfo: { currencyCode: brand === 'PE' ? 'GBP' : 'EUR', totalPriceStatus: 'FINAL', totalPrice: `${grandTotal}`, countryCode: brand === 'PE' ? 'GB' : 'IE' },
              merchantInfo: { merchantId },
              emailRequired: false,
              shippingAddressRequired: false,
            });
            const isReadyToPay = await paymentsClient.isReadyToPay({
              apiVersion: 2,
              apiVersionMinor: 0,
              allowedPaymentMethods: googlePaymentInstance.createPaymentDataRequest().allowedPaymentMethods,
              existingPaymentMethodRequired: false
            });

            if (isReadyToPay.result) {
              try {
                const paymentData = await paymentsClient.loadPaymentData(paymentDataRequest);
                const payload = await googlePaymentInstance.parseResponse(paymentData);

                if (payload.details.isNetworkTokenized === false) { // eligible for 3DS Verification
                  var threeDSecureParameters = {
                    amount: grandTotal,
                    nonce: payload.nonce,
                    bin: payload.details.bin,
                    email,
                    onLookupComplete: (data, next) => {
                      // use `data` here, then call `next()`
                      next();
                    }
                  };

                  const threeDSecure = await braintree.threeDSecure.create({ version: '2', client: clientInstance });

                  const payByCardContainer = document.getElementById('pay-by-card-container');

                  threeDSecure.on('authentication-iframe-available', (event, next) => {
                    payByCardContainer.appendChild(event.element);
                    event.element.className = 'three-d-secure-container';
                    next();
                  });

                  try {
                    let response = await threeDSecure.verifyCard(threeDSecureParameters);
                    threeDSecure.teardown();
                    var threeDSecureElem = document.getElementsByClassName('three-d-secure-container')[0];
                    if (threeDSecureElem) {
                      payByCardContainer.removeChild(threeDSecureElem);
                    }
                    payments(response);
                  } catch (e) {
                    console.error(e);
                  }
                } else {
                  payments(payload);
                }
              } catch (err) {
                if (!err.message?.includes('User closed the Payment Request UI.')) {
                  Sentry.captureException(err);
                  submitPaymentFail();
                }
                setDisablePaymentBackButton(false);
                setCurrentlySubmittingPayment(false);
              }
            }
          }
        }
      });
      var element = document.getElementsByClassName('gpay-card-info-container black pay en')[0];
      element.style.width = '100%';
      element.style.height = '48px';
      element.style.borderRadius = '48px';
      element.style.paddingTop = '4px';
    };

    renderDropIn();
  }, []);

  return (
    <div style={currentlySubmittingPayment ? { pointerEvents: 'none' } : {}}>
      <div id="button-container"></div>
    </div>
  );
};

export default PayByGooglePay;