import React, { useEffect, useState } from 'react';
import { Route, Switch, useRouteMatch, useHistory } from 'react-router-dom';
import Collection from './pages/Collection';
import OrderOnline from './pages/OrderOnline';
import { useDispatch, useSelector } from 'react-redux';
import { historyMW } from '../../helpers/routingHelpers';
import { freshRelevance, gtmEvent } from '../../helpers/commonHelpers';
import { useClearBasketDeliveryMutation, useSetRestaurantDistanceMutation, useUpdateBasketDeliveryMutation, useClearBasketMutation } from '../../services/basket.api';
import { useLazyGetRestaurantByNameQuery, useLazyGetRestaurantLatLongQuery } from '../../services/restaurant.api';
import { ChangeRestaurantModalWrapper, NotificationModalWrapper } from '../../helpers/modalHelpers';
import { setSessionSearchTerm, setSessionSearchResults } from '../../store/sessionSlice';
import { postMessageWithAwait } from '../../helpers/appHelpers';
import { clearBundles, clearActiveCategory } from '../../store/menuSlice';

export const postcodeRegex = /^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$/;

const HomeController = ({ showChangeRestaurantModal, setShowChangeRestaurantModal }) => {
  const { path } = useRouteMatch();
  const { deliveryLandingPage } = useSelector(state => state.session.features);
  const { cmsConfig, inApp, restaurantId, searchTerm } = useSelector(state => state.session);
  const { numberOfItems } = useSelector(state => state.basket);
  const [prospectiveRestuarant, setProspectiveRestaurant] = useState(null);
  const history = useHistory();
  const [updateBasketDelivery] = useUpdateBasketDeliveryMutation();
  const [clearBasket] = useClearBasketMutation();
  const { searchResults } = useSelector(state => state?.session);
  const [showSearchError, setShowSearchError] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [clearBasketDelivery] = useClearBasketDeliveryMutation();
  const [getRestaurantByName, { data, isLoading: restaurantByNameLoading, isFetching: restaurantByNameFetching, isUninitialized }, {lastArg}] = useLazyGetRestaurantByNameQuery();
  const [getRestaurantByLocation, { isLoading: restaurantByLocationLoading, isFetching: restaurantByLatLongFetching }] = useLazyGetRestaurantLatLongQuery();
  const [setRestaurantDistance] = useSetRestaurantDistanceMutation();
  const [filteredBanners, setBanners] = useState([]);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [hasUserTyped, setHasUserTyped] = useState(false);
  const {pathname} = history.location;
  const setSearchTerm = (newSearchTerm) => {
    dispatch(setSessionSearchTerm(newSearchTerm));
  };
  const setSearchResults = (SearchResults) => {
    dispatch(setSessionSearchResults(SearchResults));
  };
  
  useEffect(() => {
    if (history?.location?.search) {
      const queryParams = new URLSearchParams(history.location.search);
      const postcode = queryParams.get('postcode');
      if (!postcode) return;
      setSearchTerm(postcode);
    }
  }, []);

  const searchByText = async (isDelivery) => {
    gtmEvent(isDelivery ? 'Delivery Search' : 'Collection Search', { channel: inApp ? 'App' : 'Web' });
    if (searchTerm.trim().length && (isDelivery ? postcodeRegex.test(searchTerm.trim()) : true)) {
      setLoading(true);
      await clearBasketDelivery();
      getRestaurantByName({ postCode: searchTerm, isDelivery }, false);
    } else {
      setSearchResults(isDelivery ? { error: 'Please enter a valid postcode' } : null);
      setShowSearchError(true);
    }
  };
  
  useEffect(() => {
    if (!searchTerm.length) {
      setSearchResults(null);
      setLoading(false);
    }
    let lowerPathName = pathname?.toLowerCase() ?? '';
    if (
      !restaurantByNameFetching
      && !restaurantByNameLoading
      && !isUninitialized
      && data?.postcode?.toLowerCase() === searchTerm?.toLowerCase()
      && ("isDelivery" in lastArg && pathname !== '/' ? lastArg.isDelivery 
      && lowerPathName.includes('delivery') || !lastArg.isDelivery && lowerPathName.includes('collection') : true)
    ) {
      setLoading(false);
      handleSearchResult(data, data.isDelivery, false);
    }
  }, [searchTerm, data, restaurantByNameFetching, restaurantByNameLoading, isUninitialized]);

  const searchByLatLong = async (isDelivery) => {
    setHasUserTyped(false);
    const success = async ({ coords: { latitude, longitude } }) => {
      const result = await getRestaurantByLocation({ latitude, longitude, isDelivery });
      handleSearchResult(result.data, isDelivery, true);
      setLoading(false);
    };
    const error = (error) => {
      switch (error.code) {
        case error.PERMISSION_DENIED:
          setSearchResults({ error: 'Please grant access to location services if you wish to search by location.' });
          break;
        case error.POSITION_UNAVAILABLE:
          setSearchResults({ error: 'Location information is unavailable.' });
          break;
        case error.TIMEOUT:
          setSearchResults({ error: 'The request to get user location timed out.' });
          break;
        case error.UNKNOWN_ERROR:
          setSearchResults({ error: 'An unknown error occurred.' });
          break;
      }
      setShowSearchError(true);
      setLoading(false);
    };
    gtmEvent(isDelivery ? 'Delivery Search' : 'Collection Search', { channel: inApp ? 'App' : 'Web' });
    setLoading(true);
    if (inApp) {
      try {
        const result = await postMessageWithAwait({ type: 'GET_LOCATION' }, 'LATLONG');
        success({ coords: { latitude: result?.location?.latitude, longitude: result?.location?.longitude } });
      } catch (err) {
        setSearchResults({ error: 'Location information is unavailable. Please check your location permissions.' });
        setShowSearchError(true);
        setLoading(false);
      }
    } else {
      navigator.geolocation.getCurrentPosition(success, error);
    }
  };

  const handleSearchResult = async (result, isDelivery, latLongSearch) => {
    if (!result || result.error) {
      setShowNotification({ heading: 'Oops!', description: 'Something went wrong, please try again.' });
      return;
    } else {
      setSearchResults(result);
      setSearchTerm(result.postcode);
      const isSuccessful = isDelivery ? result.canOrderDelivery : result.canOrderCollection;
      if (isSuccessful) {
        historyMW.push('', isDelivery, history, { restaurants: result.restaurants, searchTerm, postCode: searchTerm, showClosedSites: true });
      } else {
        if (result.restaurants && isDelivery) {
          const noRestaurantsAvailableCurrently = result.restaurants?.some(r => r.isDeliveryHidden) ?? false;
          if (noRestaurantsAvailableCurrently || !isDelivery)
            historyMW.push('', isDelivery, history, { restaurants: result.restaurants, searchTerm, postCode: searchTerm, showClosedSites: true });
          if (!noRestaurantsAvailableCurrently) {
            setShowNotification({
              heading: 'We’re Sorry!',
              description: 'Unfortunately we are not currently delivering to this postcode. Why not try collection instead?',
              redirectFn: async () => {
                if (inApp) window.ReactNativeWebView?.postMessage(JSON.stringify({ type: "SWITCH_JOURNEY_NAVBAR", journey: 'collection' }));
                setSearchTerm(result.postcode);
                searchByText(false);
                historyMW.push('', false, history);
              },
              redirectCta: 'Order for collection'
            });
          }
        } else if (!isDelivery && result.restaurants?.length) {
          if (result.restaurants.length) {
            historyMW.push('', isDelivery, history, { restaurants: result.restaurants, searchTerm, postCode: searchTerm });
          }
        } else {
          setShowNotification({
            heading: 'We’re Sorry!',
            description: latLongSearch ? 'Sorry but we can\'t find your location. Please enter your postcode' : 'We have no restaurants within a 10 mile radius of your postcode.'
          });
        }
      }
    }
  };

  const handleContinue = async (restaurant, switchToCollection) => {

    const restaurantChanged = restaurant.id !== restaurantId;

    const haveItemsInBasket = numberOfItems > 0;

    if (restaurantChanged && haveItemsInBasket) {
      setShowChangeRestaurantModal(true);
    } else {
      handleRestaurantSelected(restaurant, switchToCollection);
    }
  };

  const handleRestaurantSelected = async (restaurant, switchToCollection) => {
    window.ReactNativeWebView?.postMessage(JSON.stringify({ type: "CLEAR_BASKET" }));
    const isDelivery = !switchToCollection && !!restaurant.delivery;
    if (restaurant.id && restaurantId) {
      if (restaurant.id !== restaurantId) {
        dispatch(clearActiveCategory());    
        if (isDelivery) {
          await clearBasketDelivery();
        } else {
          await clearBasket();
        }
      }
    }

    if (isDelivery) {
      const delivery = {
        ...restaurant,
        ...restaurant.delivery,
        fractional: restaurant.delivery.delivery_fee.fractional,
        currencyCode: restaurant.delivery.delivery_fee.currency_code,
        lat: restaurant.delivery.location.lat,
        lon: restaurant.delivery.location.lon,
        userPostcode: searchResults.postcode,
        deliveryQuote: restaurant.delivery
      };
      await updateBasketDelivery({ ...delivery, searchTerm, restaurantId: restaurant.id });
    } else {
      await setRestaurantDistance({ id: restaurant.id, distance: Math.round(restaurant.distance), searchTerm });
    }
    gtmEvent(isDelivery ? 'delivery_selected_restaurant' : 'collection_selected_restaurant', { id: restaurant.id, channel: inApp ? 'App' : 'Web' });
    dispatch(clearBundles());    

    freshRelevance('pageChange', null, { 'sendBeacon': true });
    setTimeout(() => setSearchResults(false), 200);
    historyMW.push(`/menu/${restaurant.id}`, isDelivery, history);
  };

  const searchProps = {
    searchTerm,
    setSearchTerm,
    searchResults,
    isLoading: loading,
    setSearchResults,
    showSearchError,
    setShowSearchError,
    showNotification,
    setShowNotification,
    searchByText,
    searchByLatLong,
    handleRestaurantSelected: handleContinue,
    hasUserTyped,
    setHasUserTyped,
    updateSearchTerm: (value) => {
      setHasUserTyped(true);
      setShowSearchError(false);
      setSearchTerm(value);
    },
  };

  useEffect(() => {
    const filteredBanners = cmsConfig?.banners?.filter(b => {
      const now = new Date();
      const time = now.toTimeString().split(' ')[0];
      if (!b.daysActive.includes(now.getDay())) return false;

      if ((b.fromTime !== null && b.fromTime > time) || (b.toTime !== null && b.toTime < time)) return false;
      const fromDate = new Date(b.fromDate);
      const toDate = new Date(b.toDate);
      if ((b.fromDate !== null && fromDate > now) || (b.toDate !== null && toDate < now)) return false;
      return true;
    });
    setBanners(filteredBanners);
  }, [cmsConfig]);

  return (
    <>
      <Switch>
        <Route exact path={path}>
          {deliveryLandingPage ?
            <OrderOnline
              searchProps={searchProps}
              banners={filteredBanners?.filter(b => b.journey?.includes('Homepage'))}
              backgrounds={cmsConfig?.backgroundConfig}
            /> :
            <Collection
              searchProps={searchProps}
              banners={filteredBanners?.filter(b => b.journey?.includes('Collection'))}
              backgrounds={cmsConfig?.backgroundConfig}
              showClosedSites={history?.location?.state?.showClosedSites}
              setProspectiveRestaurant={setProspectiveRestaurant}
            />
          }
        </Route>
        <Route exact path='/collection'>
          <Collection
            searchProps={searchProps}
            banners={filteredBanners?.filter(b => b.journey?.includes('Collection'))}
            backgrounds={cmsConfig?.backgroundConfig}
            showClosedSites={history?.location?.state?.showClosedSites}
            setProspectiveRestaurant={setProspectiveRestaurant}
          />
        </Route>
        <Route exact path='/delivery'>
          <Collection
            searchProps={searchProps}
            isDelivery
            banners={filteredBanners?.filter(b => b.journey?.includes('Delivery'))}
            backgrounds={cmsConfig?.backgroundConfig}
            showClosedSites={history?.location?.state?.showClosedSites}
            setProspectiveRestaurant={setProspectiveRestaurant}
            isFetching={restaurantByLatLongFetching || restaurantByNameFetching}
          />
        </Route>
      </Switch>
      <NotificationModalWrapper
        show={showNotification}
        onClose={() => setShowNotification(false)}
        notificationState={showNotification}
        inApp={inApp}
      />
      <ChangeRestaurantModalWrapper //
        show={showChangeRestaurantModal}
        onClose={() => setShowChangeRestaurantModal(false)}
        onChangeRestaurant={() => handleRestaurantSelected(prospectiveRestuarant, false)}
        inApp={inApp}
      />
    </>
  );
};

export default HomeController;
